import React, { useEffect, useState, useRef } from "react";
import Dropdown from "./DropdownMovil";
import { useAuth } from '../context/AuthContext'; 
import "../style/Navbar.css";
import axios from 'axios';
import LoadingBar from 'react-top-loading-bar';
import { useLocation } from 'react-router-dom';

function Navbar() {
  const navRef = useRef();
  const { authToken, logout } = useAuth();
  const [username, setUsername] = useState("");
  const [Isadmin, SetIsadmin] = useState(false);
  const loadingRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [activeSection, setActiveSection] = useState("");
  const [tel, settel]= useState("")

  const NavtoInsta = () => {
    window.location.href = "https://www.instagram.com/honduautoscom?igsh=MTh1eHJpOHIydmVzOQ==";
  };
  
  const NavtoFace = () => {
    window.location.href = "https://m.facebook.com/honduautos?mibextid=LQQJ4d";
  };
  
  const NavtoWhat = () => {
    window.location.href = "https://l.facebook.com/l.php?u=https%3A%2F%2Fapi.whatsapp.com%2Fsend%3Fphone%3D%252B50499700699%26context%3DARAbAC7ZPDUcgue8K6fARO2i3gW6YABlKSHKfgGBoXVpiqOXnQYkDAu9g5OkOQaLIdNEWEdM1JHowPgda4qXXkNjW8LhDuEugv2y9uCZ_ohEajSUSeCjDe5TdbEYOywM2bDBpICqk8BSUE-FVdYU1fQYzA%26source%3DFB_Page%26app%3Dfacebook%26entry_point%3Dpage_cta%26fbclid%3DIwZXh0bgNhZW0CMTEAAR2WBn_XF0M0stGHfpLDdAy__P1L8OuNSeUGYRh07HTlDqDkyP8qefFjNAY_aem_WSUL9XfeCs3mx_tXA_EUFw&h=AT0WmXmJ0XYKK40nKwKIrBZDArkrcM2N5YcR6dZtIQbNR6m_L3yhFyD_o_sWGCgJ_SX-6gjV20R-nZYgePD_tqjbSYawLKvcEh4Mo9uNrlMjgvcB5z4R0rPRseBiAzA&s=1";
  };
  
  const NavtoTikTok = () => {
    window.location.href = "https://l.facebook.com/l.php?u=https%3A%2F%2Fapi.whatsapp.com%2Fsend%3Fphone%3D%252B50499700699%26context%3DARAbAC7ZPDUcgue8K6fARO2i3gW6YABlKSHKfgGBoXVpiqOXnQYkDAu9g5OkOQaLIdNEWEdM1JHowPgda4qXXkNjW8LhDuEugv2y9uCZ_ohEajSUSeCjDe5TdbEYOywM2bDBpICqk8BSUE-FVdYU1fQYzA%26source%3DFB_Page%26app%3Dfacebook%26entry_point%3Dpage_cta%26fbclid%3DIwZXh0bgNhZW0CMTEAAR2WBn_XF0M0stGHfpLDdAy__P1L8OuNSeUGYRh07HTlDqDkyP8qefFjNAY_aem_WSUL9XfeCs3mx_tXA_EUFw&h=AT0WmXmJ0XYKK40nKwKIrBZDArkrcM2N5YcR6dZtIQbNR6m_L3yhFyD_o_sWGCgJ_SX-6gjV20R-nZYgePD_tqjbSYawLKvcEh4Mo9uNrlMjgvcB5z4R0rPRseBiAzA&s=1";
  };
  

  useEffect(() => {
    if (authToken !== null) {
      fetchContent()
      loadingRef.current.continuousStart(); // Start loading bar
      axios.get(process.env.REACT_APP_BACKENDIP + 'usuario/getbyToken', {
        headers: {
          'Authorization': 'bearer ' + authToken
        }
      }).then((res) => {
        setUsername(res.data.nombre);
        const roles = res.data.role;
        const containsSys = roles.includes("sysadmin");
        SetIsadmin(containsSys);
      }).finally(() => {
        setLoading(false);
        loadingRef.current.complete(); // Complete loading bar
      });
    } else {
      fetchContent()
      setLoading(false);
    }
  }, []);

  const [isFetching, setIsFetching] = useState(false);

  const fetchContent = async () => {
    if (isFetching) {
      return;
    }
    setIsFetching(true); // Set the flag to indicate the function is running
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKENDIP}content/content/Otros`
      );
      settel(res.data.body);
    } catch (error) {
      console.error('Error fetching content:', error);
    } finally {
      setIsFetching(false); // Reset the flag when the function completes
    }
  };

  useEffect(() => {
    const path = location.pathname;
    if (path === "/") {
      setActiveSection("Inicio");
    } else if (path === "/stock") {
      setActiveSection("Buscar");
    } else if (path === "/credit") {
      setActiveSection("Crédito");
    }
  }, [location.pathname]);

  return (
    <>
      <LoadingBar color="#f11946" ref={loadingRef} />
      <nav ref={navRef} className={`fixed-nav ${loading ? 'loading' : ''}`}>
        {/*Red part*/}
        <div className="red-part">
        <p className="texto-nuevo"></p>
          <div className="column-1">
            <div className="row-1"></div>
            <div className="row-2"></div>
          </div>
          <div className="column-2"></div>
          {loading ? null : Isadmin ? (
            <div className="red-part-2-admin">
              <a href="/mycars" className="admin-ref">
                <img src="/icons/user-icon.png" alt="" className="icon-nav"/>
                {username}
              </a>
              <a href="/allcarsa" className="admin-ref">
                <img src="/icons/store.png" alt="" className="icon-nav" />
                Administrar
              </a>
              <a href="/notificationsadmin" className="admin-ref">
                <img src="/icons/campana.png" alt="" className="icon-nav" />
              </a>
            </div>
          ) : (
            <div className="red-part-2">
              <img style={{cursor : "pointer"}} onClick={NavtoInsta} src="/icons/contact-icon.png" alt="" className="icon-nav" id="insta"/>
              <img style={{cursor : "pointer"}} onClick={NavtoWhat} src="/icons/white-whatsapp.png" alt="" className="icon-nav" id="insta"/>
              <img style={{cursor : "pointer"}} onClick={NavtoFace} src="/icons/white-facebook.png" alt="" className="icon-nav" id="insta"/>
              <img style={{cursor : "pointer"}} onClick={NavtoTikTok} src="/icons/white-tiktok.png" alt="" className="icon-nav" id="insta"/>
              <a className="white-link" href="/contact">
                <img src="/icons/white-phone.png" alt="" className="icon-nav" id="insta"/>
                Contáctenos
              </a>
              {authToken ? (
                <a className="white-link" href="/mycars">
                  <img src="/icons/user-icon.png" alt="" className="icon-nav" />
                  {username}
                </a>
              ) : (
                <a className="white-link" href="/login">
                  <img src="/icons/user-icon.png" alt="" className="icon-nav" />
                  Iniciar sesión
                </a>
              )}
              {authToken && !Isadmin && (
                <a className="white-link" href="/sell">
                  <img src="/icons/vende-icon.png" alt="" className="icon-nav" />
                  Vende tu auto
                </a>
              )}
              <a className="white-link" href="/politicas">
                <img src="/icons/politica-icon.png" alt="" className="icon-nav" />
                Políticas y garantías
              </a>
            </div>
          )}
        </div>
        {/*Grey part */}
        <div className="grey-part">
          {/* Logo image*/}
          <a href="/" className={`tomain ${loading ? 'loading' : ''}`}>
            <img
              src="/images/honduautos-logo.png"
              alt="Logo de honduautos"
              className="logo-honduautos"
            />
          </a>
          {/* Navigation links */}
          <div className="menu-grid">
            <a href="/" className={`nav-link ${activeSection === "Inicio" ? "active" : ""}`}>
              Inicio
            </a>
            <a href="/stock" className={`nav-link ${activeSection === "Buscar" ? "active" : ""}`}>
              Buscar
            </a>
            <a href="/credit" className={`nav-link ${activeSection === "Crédito" ? "active" : ""}`}>
              Crédito
            </a>
            <div className="drop-movil"><Dropdown/></div>
          </div>
          {/* Phone number and social media logos */}
          <div className="phone-logos-container">
            <p id="phone">Llámenos al {tel}</p>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
